import React from 'react';

import '../../../styles/index.scss';

import SEO from '../atoms/seo';
import HOC from '../organisms/root';

import Grid, { GridRow, GridCol } from '../atoms/grid';
import { ContentContainer } from '../molecules/container';
import MainNavigation from '../organisms/mainNavigation';
import { Devider, Spacer } from '../atoms/devider';
import Footer from '../molecules/footer';

import { getPageData, getCurrentPageProps, getPath, isBrowser } from '../../../utils';

const Layout = ({ pageContext, ...props }) => {
  if (!pageContext) return <></>;

  if (isBrowser() && pageContext.slug === '/statics/cookies-and-privacy-policy') {
    window.localStorage.setItem('cookiesConsent', JSON.stringify({ isOk: true }));
  }

  const { data } = (pageContext && getPageData(pageContext.data, pageContext.type, pageContext.slug));
  
  return (
    <HOC>
      <SEO {...data.seo} />
      <MainNavigation navLinks={pageContext.data.mainNavLinks} pageType="main" />
      <Spacer spacing={20} />
      <Spacer spacing={20} />
      <Grid gap={20} noPadding={true} offset={true}>
        <GridRow direction="row">
          <GridCol colSize={12} stretched={true}>
            <ContentContainer isSectionTitle={true} content={getCurrentPageProps(pageContext.data.contentContainer, getPath(), "cookies-and-privacy-policy")} />
          </GridCol>
        </GridRow>
      </Grid>
      <Grid gap={10} noPadding={true} offset={true}>
          <Devider margin={10} />
          <GridRow direction="row">
            <GridCol colSize={10}>
              <Footer title={pageContext.data.footerTitle} navLinks={{ items: pageContext.data.footerNavLinks.items }} />
            </GridCol>
            <GridCol colSize={2} align="right">
              <Footer navLinks={{ ctas: pageContext.data.footerNavLinks.ctas }} />
            </GridCol>
          </GridRow>
        </Grid>
        <Spacer spacing={20} />
    </HOC>
  );
};

export default Layout;
